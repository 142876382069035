
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import dayjs from 'dayjs'
  import { ActivityType, LeadActivity } from '@/entities/crm'

@Component
  export default class TimeSlot extends Vue {
  @Prop({ type: String, required: true }) time!: string;
  @Prop({ type: Object, default: null }) agenda!: LeadActivity;
  @Prop({ type: Boolean, default: false }) selected!: boolean;
  @Prop({ type: Boolean, default: false }) borderTop!: boolean;
  @Prop({ type: Boolean, default: false }) borderBottom!: boolean;
  @Prop({ type: Boolean, default: false }) allowSelect!: boolean;
  @Prop({ type: Boolean, default: false }) isNewAgendaStart!: boolean;
  @Prop({ type: Number, default: 5 }) slotInterval!: number;
  @Prop({ type: Object, default: () => ({}) }) newAgendaTitle!: ActivityType;
  @Prop({ type: String, default: '' }) date!: string;
  @Prop({ type: String, default: '' }) currentTime!: string;
  @Prop({ type: String, default: '' }) color!: string;
  @Prop({ type: Object, default: () => ({}) }) newAgenda!: any;

  borderSelectedTop = false
  borderSelectedBottom = false
  isSelected = false
  now = dayjs().startOf('day')

  get isClickable () {
    const { agenda } = this

    return agenda?.task?.lead?.client?.id
  }

  get agendaBorderTop () {
    const { agenda, time } = this

    return agenda?.timeStart === time
  }

  get agendaBorderBottom () {
    const { agenda, time, now, slotInterval } = this
    if (!agenda?.timeEnd) return false

    const end = dayjs(`${now.format('MM-DD-YYYY')} ${agenda.timeEnd}`)
    const lastTime = dayjs(`${now.format('MM-DD-YYYY')} ${time}`)

    const endMinusInterval = end.subtract(slotInterval, 'minutes')

    return lastTime.isSame(endMinusInterval)
  }

  get agendaBorderSides () {
    const { agenda, time, now, slotInterval } = this
    if (!agenda?.timeStart || !agenda?.timeEnd) return false

    const start = dayjs(`${now.format('MM-DD-YYYY')} ${agenda.timeStart}`)
    const endMinusInterval = dayjs(`${now.format('MM-DD-YYYY')} ${agenda.timeEnd}`).subtract(slotInterval, 'minutes')
    const lastTime = dayjs(`${now.format('MM-DD-YYYY')} ${time}`)

    return lastTime.isAfter(start) && lastTime.isBefore(endMinusInterval)
  }

  get isAgenda () {
    const { agenda } = this
    if (!agenda) return undefined
    return agenda
  }

  get agendaIcon () {
    const { isAgenda } = this
    if (!isAgenda) return ''
    return isAgenda.task?.type?.icon
  }

  get agendaPipeline () {
    const { isAgenda } = this
    if (!isAgenda) return ''
    return isAgenda.lead?.pipeline
  }

  get agendaPipelineColor () {
    const { agendaPipeline } = this

    if (!agendaPipeline) return ''

    return agendaPipeline.color
  }

  get agendaPipelineIcon () {
    const { agendaPipeline } = this

    if (!agendaPipeline) return ''

    return agendaPipeline.icon
  }

  get newAgendaIsSelected () {
    const { newAgendaBorderTop, newAgendaBorderBottom, newAgendaBorderSides } = this

    return newAgendaBorderTop || newAgendaBorderBottom || newAgendaBorderSides
  }

  get newAgendaBorderTop () {
    const { newAgenda, time, isSelected } = this
    if (!isSelected) return false

    return newAgenda?.timeStart === time
  }

  get newAgendaBorderBottom () {
    const { newAgenda, time, now, isSelected, slotInterval } = this
    if (!newAgenda?.timeEnd || !isSelected) return false

    const end = dayjs(`${now.format('MM-DD-YYYY')} ${newAgenda.timeEnd}`)
    const lastTime = dayjs(`${now.format('MM-DD-YYYY')} ${time}`)

    const endMinusInterval = end.subtract(slotInterval, 'minutes')

    return lastTime.isSame(endMinusInterval)
  }

  get newAgendaBorderSides () {
    const { newAgenda, time, now, isSelected, slotInterval } = this
    if (!newAgenda?.timeStart || !newAgenda?.timeEnd || !isSelected) return false

    const start = dayjs(`${now.format('MM-DD-YYYY')} ${newAgenda.timeStart}`)
    const endMinusInterval = dayjs(`${now.format('MM-DD-YYYY')} ${newAgenda.timeEnd}`).subtract(slotInterval, 'minutes')
    const lastTime = dayjs(`${now.format('MM-DD-YYYY')} ${time}`)

    return lastTime.isAfter(start) && lastTime.isBefore(endMinusInterval)
  }

  get isSelectable (): boolean {
    const { selected, agenda } = this
    return !selected && !agenda
  }

  get removeSlot () {
    const { timeSelectable, allowSelect } = this
    return {
      remove: !timeSelectable,
      recalculate: allowSelect,
    }
  }

  get agendaColor () {
    const { color } = this
    return color
  }

  get timeSelectable () {
    const { time, date, currentTime, now } = this

    const dateFormatted = dayjs(`${date} ${currentTime}`, 'DD/MM/YYYY HH:mm')

    if (dateFormatted.isAfter(now) && !dateFormatted.isSame(now)) {
      return true
    }

    return this.isTimeAfterCurrent(time, currentTime, now)
  }

  isTimeAfterCurrent (time, currentTime, now) {
    const newDate = now.format('DD/MM/YYYY')
    const newTime = dayjs(`${newDate} ${currentTime}`, 'HH:mm')

    const timeFormatted = dayjs(`${newDate} ${time}`, 'HH:mm')
    return timeFormatted.isAfter(newTime)
  }

  onMouseDown () {
    if (this.isSelectable && this.allowSelect && this.timeSelectable) {
      this.$emit('mousedown', this.time)
    }
  }

  onMouseUp () {
    if (this.isSelectable && this.allowSelect && this.timeSelectable) {
      this.$emit('mouseup', this.time)
    }
  }

  displayTime (time) {
    return time.split(':')[1] === '00' ? time : ''
  }

  isTimeInRange (currentTime, agenda, now) {
    if (!agenda) return true
    const { timeStart, timeEnd } = agenda

    return this.isTimeAfterCurrent(currentTime, timeEnd, now) && this.isTimeAfterCurrent(currentTime, timeStart, now)
  }

  @Watch('removeSlot', { immediate: true, deep: true })
  onRemoveSlot ({ remove }) {
    const { currentTime, agenda, now } = this

    if (remove && this.isTimeInRange(currentTime, agenda, now)) {
      this.$emit('remove-slot', this.time)
    }
  }
  }
